<template>
    <div>
        <List
            :columns="columns"
            :get_table_list="get_table_list"
            @handle_details_click="handle_details_click"
			:submit_preprocessing="submit_preprocessing"
            :form_data_seo.sync="form_data_seo"
			ref="list"
			:is_created_get_list="false"
        >
            <template slot="left_btn">
                <a-button
				    type="primary"
                    v-if="this.$store.state.page_auth.batch"
				    style="margin-left: 15px;"
				    @click="transfer_status"
				>批量转交</a-button>
            </template>
			<template slot="right_btn">
                <a :href="'/manage/crm.export/launchExport' | export_form" v-if="this.$store.state.page_auth.export" target="_blank">
					<a-button  class="a_btn">导出</a-button>
				</a>
            </template>
		</List>
        <RightDrawer :visible.sync="visible">
            <template slot="content">
                <ApprovalHeader :details="details"></ApprovalHeader>
                <a-divider />
                <a-descriptions>
                    <a-descriptions-item :span="3" label="所属部门">{{details.department}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="岗位职务">{{details.role}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="审批编号">{{details.id}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="申请客户">{{details.customer.name}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="联系方式">{{details.customer.contact_phone}}</a-descriptions-item>
                    <a-descriptions-item
                        :span="3"
                        label="门店地址 "
                    >{{details.customer.province + details.customer.city + details.customer.district + details.customer.address}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="投放类型">{{details.type}}</a-descriptions-item>
                    <!-- <a-descriptions-item :span="3" label="投放金额">{{details.tatol_price}}</a-descriptions-item> -->
                    <a-descriptions-item :span="3" label="申请事由">{{details.desc}}</a-descriptions-item>
					<a-descriptions-item :span="3" label="投放周">{{details.put_week}}</a-descriptions-item>
					
                    <a-descriptions-item :span="3" label="图片">
                        <!-- <img class="leave_img" src alt /> -->
                    </a-descriptions-item>
					<a-descriptions-item :span="2">
					    <LookImages v-if="details.leave_img" :list="details.leave_img.split(',')"></LookImages>
					</a-descriptions-item>
                </a-descriptions>
                <a-table :columns="columns_details" :pagination="false" :dataSource="details.goods">
                    <!-- <template slot="footer" slot-scope>
                        <div
                            style="text-align:right"
                        >共{{details.goods_num}}件商品，总计：¥{{details.tatol_price}}</div>
                    </template> -->
                </a-table>

                <!-- <a-divider /> -->

                <div class="drawer_title" style="margin-top:15px">审批进度</div>
                <ApprovalSpeed :list="details.approver"></ApprovalSpeed>
                <div class="drawer_title" v-if="details.cclist.length">抄送</div>
                <ApprovalCc :list="details.cclist"></ApprovalCc>
            </template>
        </RightDrawer>

        <!-- 批量修改经销商弹窗 -->
		<a-modal
			title="批量转交"
			:visible="transfer_state"
			@cancel="transfer_cancle"
			@ok="transfer_investment"
		>
			<div class="has_seleted_customer">
				<div class="select_item">
					<div class="must_dot">原审批人：</div>
					<a @click="user_type = 'old_user';user_chose = true">{{old_user.username ? (old_user.username || '请选择') : '请选择'}}</a>
				</div>
				<div class="select_item">
					<div class="must_dot">转交人</div>
					<a @click="user_type = 'new_user';user_chose = true">{{new_user.username ? (new_user.username || '请选择') : '请选择'}}</a>
				</div>
			</div><!-- has_seleted_customer -->
		</a-modal>
		<!-- 人员选中弹窗 -->
        <SelectUser @submit="submit" :visible.sync="user_chose"></SelectUser>
    </div>
</template>

<script>
import List from './components/list'
import SelectUser from "@/components/SelectUser";
import { investmentList, investmentInfo ,getType,investmentListPut,investmentPutInfo,transferApproval} from '@/api/approval'
import RightDrawer from "@/components/RightDrawer";
import ApprovalSpeed from "@/components/ApprovalSpeed";
import ApprovalCc from "@/components/ApprovalCc";
import ApprovalHeader from "@/components/ApprovalHeader";
import LookImages from "@/components/LookImages";
import {getCostType} from "@/api/cost";
const columns_details = [
    {
        title: "品项名称",
        dataIndex: "goods_title",
    },
    {
        title: "数量",
        dataIndex: "num",
    },
    {
        title: "投放时间",
        dataIndex: "launch_time",
    },
    {
        title: "投放位置",
        dataIndex: "putin",
    },
];

const columns = [
    {
        title: "审批编号",
        dataIndex: "id",
    },
    {
        title: "标题",
        dataIndex: "title",
    },
    {
        title: "部门",
        dataIndex: "path_name",
    },
    {
        title: "申请客户",
        dataIndex: "name",
    },
    // {
    //     title: "投放金额",
    //     dataIndex: "tatol_price",
    // },
    {
        title: "投放类型",
        dataIndex: "type",
    },
	{
	    title: "投放周",
	    dataIndex: "launch_time",
	},
    {
        title: "申请事由",
        dataIndex: "desc",
    },
    {
        title: "审批状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        width: 150,
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    components: {
		LookImages,
        List,
        RightDrawer,
        ApprovalSpeed,
        ApprovalCc,
        ApprovalHeader,
        SelectUser
    },
    data() {
        return {
            user_type: {},
            old_user: {},
            new_user: '',
            user_chose: false,
            transfer_state: false,
            columns,
			columns_details,
			submit_preprocessing: {
                array_to_string: ['department','type']
            },
            get_table_list: investmentListPut,
            visible: false,
            details: {
                cclist: [],
                customer: {},
                goods: []
            },
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: 'text',
                        name: 'customer',
                        title: '客户',
                        placeholder: '客户名称/ID/地址'
                    },
                    {
                        type: "tree-select",
                        name: "type",
                        title: "投放类型",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    // {
                    //     type: 'select',
                    //     name: 'type',
                    //     title: '投放类型',
                    //     list: [

                    //     ]
                    // },
					// {
					//     type: 'select',
					//     name: 'type2',
					//     title: '投放状态',
					//     list: [
					
					//     ]
					// }
                ]
            }
        }
	},
	created() {
        // getType({
        //     data: {
        //         type: 'investment'
        //     }
        // }).then(res => {
        //     let type_list = this.$method.to_form_list(res.data.list, 'id', 'name')
        //     this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type', 'list', type_list)
		// })
        getCostType().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type', 'treeData', res.data.list)
        })
		let query = this.$route.query;
		this.$nextTick(()=>{
			this.$refs.list.$refs.list.fixed_seo_data = {
				...query,
			}
			this.$refs.list.$refs.list.get_list();
		})
    },
    methods: {
        handle_details_click({ record }) {
            investmentPutInfo({
                data: {
                    id: record.id
                }
            }).then(res => {
                let data = res.data;
                this.details = data;
                this.visible = true;
            })

        },
        transfer_status() {
            this.transfer_state = true;
        },
        transfer_cancle() {
            this.transfer_state = false;
            this.old_user = {};
            this.new_user = {};
        },
        transfer_investment() {
            transferApproval({
                data: {
                    old_user: this.old_user.id,
                    new_user: this.new_user.id,
                    type: 'put_in',
                },
				info:true
            }).then(res => {
                this.transfer_state = false;
                this.old_user = {};
                this.new_user = {};
            })
        },
        submit({
                data
            }) {
                this.user_chose = false;
                this.select_user = data;
                if (this.user_type == 'old_user') {
                    this.old_user = data
                }
                if (this.user_type == 'new_user') {
                    this.new_user = data
                }
            },
    },
}
</script>
<style lang="less" scoped>
	@import "../../assets/less/editor.less";
	.has_seleted_customer{
	.customer_box{
		.item{
			margin-bottom: 8px;
		}
	}
	.select_item{
		margin-top: 12px;
		display: flex;
		.must_dot{
			min-width: 105px;
		}
	}
}/* has_seleted_customer */
</style>